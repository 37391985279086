<template>
  <section id="dashboard-main" class="mt-2">
    <b-row class="match-height">
        <!-- 미팅룸 조회 리스트 -->
        <b-col
        xl="12"
        lg="12"
        md="12"
        class="ecs-card"
        >
          <resv-list @getSn="setSn" :items="this.resvItems" :active="this.active" :total="this.total" @callParent="setPages"
           :areaOption="this.areaOption"></resv-list>
        </b-col>
        <!-- 미팅룸 조회 리스트 END -->
    </b-row>
    
  </section>
</template>
<script>
import {
  BRow, BCol,
} from 'bootstrap-vue'

import ResvList from './components/ResvList.vue'

const qs = require('qs')

export default {
  components: {
    BRow,
    BCol,
    ResvList,
  },
  data() {
    return {
      data: {},
      resvItems: [],
      resvUrl: '/cmsreserve/selectCmsReserveListJson',
      cmsServerUrl: '/cmsmaster/selectCmsMasterListAllJson',
      active: true,
      offset : 0,
      total : '0',
      param: {},
      serverParam: {},
      sn: 0,
      areaOption: [
        {value: 0, text: '전체 서버'}
      ],
      search: {
        serverSn: 0
      }
    }
  },
  watch: {
    /**
     * 페이징 Index 변할 시 offset 별 API 호출
     * @created 2021-04-23
     * @author 박재헌
     * @comment 자식 컴포넌트에서 변경된 currentPage 감지하여 offset 변경 후 API 페이징인덱스로 재호출
     */
    offset(){
      // this.getMeeting(this.searchItem)
    },
    /**
     * 서버 선택 시 서버별 API 호출
     * @created 2021-04-23
     * @author 박재헌
     * @comment 자식 컴포넌트에서 변경된 서버 순번 감지 후 해당 서버로 API 재호출
     */
    sn(){
      // this.getResv(this.searchItem)
    }
  },
  computed: {
  },
  created() {
  },
  mounted() {
    this.getServer()
    this.getResv()
  },
  methods: {
    /**
     * 서버 선택 sn 변경
     * @created 2021-04-23
     * @author 박재헌
     * @comment 자식 컴포넌트에서 변경된 서버 순번을 세팅하기 위한 함수 getSn으로 $emit 받음
     */
    setSn(sn){
      this.sn = sn 
      this.search.serverSn = sn
      this.getResv()
    },
    /**
     * CMS 서버 리스트 조회
     * @create 2021-05-17
     * @author 박재헌
     * @returns {json}
     * @description 서버리스트 조회
     */
    getServer() {
      this.$jBack({
        method: 'post',
        url: this.cmsServerUrl,
        data: qs.stringify(this.serverParam)
      })
      .then(response => {
        // console.log('CMS SERVER DATA response ********** ',response)
        const serverList = response.data.resultList
        this.areaOption = [
          {value: 0, text: '전체 서버'}
        ]
        for (let index = 0; index < serverList.length; index++) {
          const element = serverList[index];
          this.areaOption.push({value: element.sn, text: element.name})
        }
      })
      .catch(error => {
        console.log(error)
        alert('미팅 예약 목록 조회에 실패하였습니다.')
        this.active = true
      })
    },
    /**
     * 미팅 예약 조회 DB 조회
     * @create 2021-02-17
     * @author 박재헌
     * @returns {json}
     * @description 미팅 예약 조회
    */
    getResv() {
      this.active = false
      this.param = {
        tbCmsMatserSn : this.search.serverSn, // CMS SERVER SN
      }
      this.$jBack({
        method: 'post',
        url: this.resvUrl,
        data: qs.stringify(this.param)
      })
      .then(response => {
        // console.log('Reserve DATA response ********** ',response)
        if(response.data.resultList != []){
              this.total = response.data.resultList.length.toString()
              this.resvItems = response.data.resultList
              this.active = true
          }else {
            this.active = true
          }
      })
    },
    /**
     * 페이징번호 부모에 넘기기 전 변경
     * @created 2021-04-23
     * @author 박재헌
     * @param {{Number}} num
     * @comment 페이징 index 클릭 시 offset을 클릭한 index로 변경 
     * 자식 컴포넌트에서 부모 컴포넌트로 보내기 위한 함수
     */
    setPages(num) {
      this.offset = num
    }
  },
}
</script>

<style lang="scss">
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
