<template>

  <!-- Table Container Card -->
  <b-card
    no-body
  >
    <div class="m-1">
      <!-- Table Top -->
      <b-row>
        <!-- 타이틀 -->
        <b-col
          cols="12"
          xl="12"
          lg="12"
          md="12"
          class="clearfix"
        >
          <h4 class="float-left font-weight-bolder mt-8px">미팅 예약 조회</h4>
          <div class="justify-content-center spinner-custom float-right ml-1" :class="{ 'd-none' : active }">
              <b-spinner
                v-for="variant in variants"
                :variant="variant"
                :key="variant"
              ></b-spinner>
          </div>
          <b-form-select
              class="float-right col-md-2"
              v-model="selectArea"
              size=""
              :options="areaOption"
              placeholder="권역"
            />
        </b-col>
        <!-- 타이틀 END -->
      </b-row>

    </div>
    <div class="pl-1 pr-1">
    <!-- table -->
        <vue-good-table
        :columns="columns"
        :rows="this.items"
        :rtl="direction"
        v-dragscroll="{ target: '.vgt-responsive' }"
        :search-options="{
            enabled: false,
            trigger: 'enter',
            placeholder: '검색어를 입력해주세요.',
          }"
        :sort-options="{
          enabled: false,
        }"
        :pagination-options="{
            enabled: true,
            perPage:pageLength
        }"
        theme="my-theme"
        class="table table-light"
        @on-row-click="onRowClick"
        >
        <div slot="emptystate" class="text-center">
          조회된 목록이 없습니다.
        </div>
        <template
            slot="table-row"
            slot-scope="props"
        >
            <!-- Column: Name -->
            <div v-if="props.column.field === 'index'" class="text-nowrap">
                {{ props.row.originalIndex + 1 }}
            </div>
            <div v-if="props.column.field === 'tbCmsMasterName'" class="text-nowrap">
               {{ props.formattedRow.tbCmsMasterName }}
            </div>
            <div v-if="props.column.field === 'tbCmsMasterIp'" class="text-nowrap">
                {{ props.formattedRow.tbCmsMasterIp }}
            </div>
            <div v-if="props.column.field === 'title'" class="text-nowrap">
                {{ props.formattedRow.title }}
            </div>
            <div v-if="props.column.field === 'resvSeq'" class="text-nowrap">
                {{ props.formattedRow.resvSeq }}
            </div>
            <div v-if="props.column.field === 'startDt'" class="text-nowrap">
                {{ props.formattedRow.startDt }}
            </div>
            <div v-if="props.column.field === 'endDt'" class="text-nowrap">
                {{ props.formattedRow.endDt }}
            </div>
        </template>
        
        <!-- pagination -->
        <template
            slot="pagination-bottom"
            slot-scope="props"
        >
            <div class="d-flex justify-content-end flex-wrap">
            <div>
                <b-pagination
                :value="1"
                v-model="currentPage"
                :total-rows="total"
                :per-page="perPage"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="m-2"
                @input="(value)=>props.pageChanged({currentPage:value})"
                >
                <template #prev-text>
                    <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                    />
                </template>
                <template #next-text>
                    <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                    />
                </template>
                </b-pagination>
            </div>
            </div>
        </template>
        </vue-good-table>
      </div>
      <resv-detail :item="this.detailItem" ></resv-detail>
  </b-card>
  
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BTable, BFormSelect, BButton, VBToggle, BSpinner,
  BPagination,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import { dragscroll } from 'vue-dragscroll'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'

import ResvDetail from './ResvDetail.vue' // 미팅 예약 상세

const qs = require('qs')

export default {
  directives: {
    Ripple,
    dragscroll,
    'b-toggle': VBToggle,
  },
  components: {
    BCard,
    BRow,
    BCol,
    BSpinner,
    BFormInput,
    BTable,
    BFormSelect,
    BButton,
    BPagination,
    VueGoodTable,

    ResvDetail,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    active: {
      type: Boolean,
      default: true,
    },
    total: {
      type: String,
      default: '0',
    },
    areaOption: {
      type: Array,
      default: () => [],
    }
  },
  data() {
    return {
      data: {
      },
      detailUrl: '/cmsreservedetail/selectCmsReserveDetailListJson',
      detailSet: [
        {
          atchFileId: null,
          callid: "",
          callnumber: "",
          descasc: "",
          endDt: "YYYY-MM-DD HH:mm",
          firstIndex: 1,
          frstRegistPnttm: "YYYY-MM-DD HH:mm:ss",
          frstRegisterId: "webmaster",
          lastIndex: 1,
          lastUpdtPnttm: "YYYY-MM-DD HH:mm:ss",
          lastUpdusrId: "",
          meetingId: "",
          name: "",
          orderby: "",
          orderbylist: null,
          pageIndex: 1,
          pageSize: 10,
          pageUnit: 10,
          recordCountPerPage: 10,
          registId: "",
          reserveSn: 0,
          resvSeq: "",
          searchCondition: "",
          searchKeyword: "",
          searchKeywordFrom: "",
          searchKeywordTo: "",
          searchUseYn: "",
          serverIp: "",
          serverName: "",
          serverSn: 0,
          smsChk: "",
          sn: 0,
          startDt: "YYYY-MM-DD HH:mm",
          title: "",
          todayResv: 0,
          totResv: 0,
          totalSearch: ""
        }
      ],
      detailItem: [],
      variants: ['primary'],
      pageLength: 10,
      dir: false,
      searchItem: '',
      metDetailUrl: '/api/v1/meeting/',
      columns: [
        { field: 'index', label: '번호', width: '53px'},
        { field: 'tbCmsMasterName', label: '권역명', sortable: true, sortFn: this.sortFn,
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: '권역명', // placeholder for filter input
          },
        },
        { field: 'tbCmsMasterIp', label: '권역 IP', sortable: true, sortFn: this.sortFn,
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: '권역 IP', // placeholder for filter input
          },
        },
        { field: 'resvSeq', label: '고유 번호', width: '85px', sortable: true, sortFn: this.sortFn,
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: '고유 번호', // placeholder for filter input
          },
        },
        { field: 'title', label: '이름',  sortable: true, sortFn: this.sortFn,
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: '이름', // placeholder for filter input
          },
        },
        { field: 'startDt', label: '시작 일시', sortable: true, sortFn: this.sortFn,
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: '시작 일시', // placeholder for filter input
          },
        },
        { field: 'endDt', label: '종료 일시' , sortable: true, sortFn: this.sortFn,
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: '종료 일시', // placeholder for filter input
          },
        },
      ],
      perPage: 10,
      currentPage: 1,
      selectArea: 0,
      // areaOption : []
    }
  },
  watch: {
    /**
     * 페이징 Index Watch
     * @created 2021-04-26
     * @author 박재헌
     * @param {Number} val
     * @comment 페이징이 변할 시 setOffset 함수 발생하여 부모컴포넌트 Resv.vue에 변한 Index $emit
     */
    currentPage(val){
      this.setOffset(val)
    },
    /**
     * 서버 선택 셀렉트 Watch
     * @created 2021-04-23
     * @author 박재헌
     * @param {Number} val
     * @commnet 서버 선택 시 해당 서버 Sn 부모컴포넌트 Resv.vue에 $emit
     */
    selectArea(val){
      this.selectArea = val
      this.$emit("getSn", val)
    }
  },
  mounted() {
    // this.setCmsServer()
    /**
     * CMS 위젯 이동 서버 자동선택
     * @created 2021-04-16
     * @author 박재헌
     * @param {number} $route.query.selectArea
     * 서버순번으로 서버선택 셀렉트 val 자동 선택
     */
    if(this.$route.query.selectArea != undefined || this.$route.query.selectArea != null){
      this.selectArea = this.$route.query.selectArea
    }else{

    }
    this.$emit("getSn", this.selectArea)
  },
  methods: {
    /**
     * CMS 행 클릭 상세조회 이벤트
     * @created 2021-04-20
     * @author 박재헌
     * @param {Array} data 테이블 내부 컴포넌트 행 데이터
     * @comment 상세조회할 미팅리스트의 데이터 안 callGuid로 상세조회 API 실행
     */
    onRowClick(data, d) {
      this.getReserveDetailProps(data.row.resvSeq)
      this.$root.$emit('bv::toggle::collapse', 'sidebar-send-invoice')
    },
     /**
     * CMS 페이징
     * @created 2021-04-20
     * @author 박재헌
     * @param {Number} currentPage 페이지 Index
     * @commnet currentPage를 부모 Calls 컴포넌트에 전송, 페이징하여 리스트 API 재실행
     */
    setOffset(){
      this.$emit('callParent', this.currentPage)
    },
    /**
     * CMS 미팅 예약 상세 코덱리스트 조회
     * @created 2021-05-18
     * @author 박재헌
     * @param {Number} resvSeq 미팅 예약 고유번호
     * @comment 미팅 예약 고유번호로 해당 미팅 상세 코덱리스트 조회
     */
    getReserveDetailProps(seq) {
      this.detailItem = this.detailSet
      const param = {
        resvSeq: seq
      }
      this.$jBack({
        method: 'post',
        url: this.detailUrl,
        data: qs.stringify(param)
      })
      .then(response => {
        // console.log('Detail ************* ', response)
        if(response.data.resultList.length > 0){
          this.detailItem = response.data.resultList
        }else{
          this.detailItem = this.detailSet
        }
      })
      .catch(error => {
        console.log(error)
        alert('미팅 예약 상세조회에 실패하였습니다.')
      })
    }
  },
  computed: {
    rows() {
      return this.items.length
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
