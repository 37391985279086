<template>
  <b-sidebar
    id="sidebar-send-invoice"
    sidebar-class="sidebar-lg"
    bg-variant="white"
    shadow
    backdrop
    no-header
    right
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h4 class="mb-0 font-weight-bolder">
          미팅예약 상세조회
        </h4>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
      <!-- Header End -->

      <!-- Body -->
      <b-form
        @submit.prevent
      >
          <b-table
          ref="refInvoiceListTable"
          :items="items"
          responsive
          :fields="detailColums"
          show-empty
          empty-text="조회된 목록이 없습니다."
          class="custom position-relative table-setting text-left mb-0"
          >
          </b-table>
      </b-form>
      <div class="p-10px">
        <vue-good-table
          :columns="columns"
          :rows="codecList"
          v-dragscroll="{ target: '.vgt-responsive' }"
          :search-options="{
              enabled: false,
            }"
          :sort-options="{
            enabled: false,
          }"
          :pagination-options="{
              enabled: true,
              perPage:pageLength
          }"
          theme="my-theme"
          class="table table-light"
          >
          <div slot="emptystate" class="text-center">
            조회된 목록이 없습니다.
          </div>
          <template
              slot="table-row"
              slot-scope="props"
          >
              <!-- Column: Name -->
              <div v-if="props.column.field === 'index'" class="text-nowrap">
                  {{ props.row.originalIndex + 1 }}
              </div>
              <div v-if="props.column.field === 'name'" class="text-nowrap">
                {{ props.formattedRow.name }}
              </div>
              <div v-if="props.column.field === 'callnumber'" class="text-nowrap">
                  {{ props.formattedRow.callnumber }}
              </div>
          </template>
          
          <!-- pagination -->
          <template
              slot="pagination-bottom"
              slot-scope="props"
          >
              <div class="d-flex justify-content-end flex-wrap">
              <div>
                  <b-pagination
                  :value="1"
                  v-model="currentPage"
                  :total-rows="total"
                  :per-page="perPage"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="m-2"
                  @input="(value)=>props.pageChanged({currentPage:value})"
                  >
                  <template #prev-text>
                      <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                      />
                  </template>
                  <template #next-text>
                      <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                      />
                  </template>
                  </b-pagination>
              </div>
              </div>
          </template>
          </vue-good-table>
        </div>
    </template>
  </b-sidebar>
</template>
<style>
.custom.table-setting th div{
  display: none;
}
.custom.table-setting table thead th:first-child{
  width: 29%;
}
.custom.table-setting table thead th{
  padding: 0;
}
.custom.table-setting table tbody td{
  padding-left: 1.5rem !important;
  text-align: left;
}
</style>
<script>
import {
  BSidebar, BForm, BTable, VBToggle, BPagination
} from 'bootstrap-vue'

import { dragscroll } from 'vue-dragscroll'
import { VueGoodTable } from 'vue-good-table'

export default {
  components: {
    BSidebar, 
    BForm, 
    BTable,
    BPagination,
    VueGoodTable,
  },
  directives: {
    'b-toggle': VBToggle,
    dragscroll,
  },
  props: {
    item: Array,
    default: () => [],
  },
  data() {
    return {
      data: {
      }, 
      columns: [
        { field: 'index', label: '번호', width: '53px'},
        { field: 'name', label: '이름',
        },
        { field: 'callnumber', label: 'Call Number',
        },
      ],
      codecList: [],
      detailColums: [
        { key: 'ce_ver', label: '미팅룸 상세'},
        { key: 'commend', label: ''},
      ],
      items: [
        {
          'ce_ver': '서버 이름',
          'commend': ``,
        },
        {
          'ce_ver': '서버 IP',
          'commend': ``,
        },
        { 
          'ce_ver': '예약 제목',
          'commend': ``,
        },
        {
          'ce_ver': '개설자',
          'commend': ``,
        },
        {
          'ce_ver': '예약 고유번호',
          'commend': `0`,
        },
        {
          'ce_ver': '미팅 아이디',
          'commend': ``,
        },
        {
          'ce_ver': '방번호',
          'commend': `0`,
        },
        {
          'ce_ver': '예약 시작시간',
          'commend': `YYYY-MM-DD HH:mm`,
        },
        {
          'ce_ver': '예약 종료시간',
          'commend': `YYYY-MM-DD HH:mm`,
        },
      ],
      // pagination
      pageLength: 10,
      perPage: 10,
      currentPage: 1,
      total: 0,
    }
  },
  watch: {
    item(val) {
      this.codecList = []
      if(val[0].callid){
        this.codecList = val
        this.total = this.codecList.length
      }
      this.items = [
        {
          'ce_ver': '서버 이름',
          'commend': val[0].serverName,
        },
        {
          'ce_ver': '서버 IP',
          'commend': val[0].serverIp,
        },
        { 
          'ce_ver': '예약 제목',
          'commend': val[0].title,
        },
        {
          'ce_ver': '개설자',
          'commend': val[0].registId,
        },
        {
          'ce_ver': '예약 고유번호',
          'commend': val[0].resvSeq,
        },
        {
          'ce_ver': '미팅 아이디',
          'commend': val[0].meetingId,
        },
        {
          'ce_ver': '방번호',
          'commend': val[0].callid,
        },
        {
          'ce_ver': '예약 시작시간',
          'commend': val[0].startDt,
        },
        {
          'ce_ver': '예약 종료시간',
          'commend': val[0].endDt,
        },
      ]
    }
  },
  mounted() {
      
  },
  methods: {
  },
}
</script>

<style>

</style>
